import { useTranslations } from 'next-intl';
import { CartItem } from '../../../stores/cart/types';
import CartItemComponent from './CartItem';

interface Props {
  items: CartItem[];
}

export default function CartList(props: Props) {
  const t = useTranslations('common');
  if (props.items.length === 0) {
    return (
      <div className="tw-text-base tw-text-greyscale-placeholder tw-text-center">
        {t('DrawerCart.empty')}
      </div>
    );
  }

  return (
    <ul className="tw-mb-0 tw-pl-0 tw-space-y-8">
      {props.items.map((item: CartItem) => (
        <CartItemComponent product={item.product} key={item.product.id} />
      ))}
    </ul>
  );
}
