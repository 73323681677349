import {IMGPW} from '@/config';
import {Link} from '@/navigation';
import Image from 'next/image';

export default function Logo() {
  return (
    <Link href="/">
      <div className="md:tw-block tw-hidden">
        <div className="tw-relative tw-h-10 tw-w-32">
          <Image
            fill
            src={IMGPW + '/logo_de.png'}
            alt="Logo Winelivery"
            className="tw-object-contain"
          />
        </div>
      </div>
      <div className="md:tw-hidden">
        <div className="tw-relative tw-h-10 tw-w-10">
          <Image
            fill
            src={IMGPW + '/logo_winelivery_small.png'}
            alt="Logo Winelivery"
            className="tw-object-contain"
          />
        </div>
      </div>
    </Link>
  );
}
