import QuantityManager from '@/app/[locale]/shop/components/commons/QuantityManager';
import useProduct from '@/app/[locale]/shop/components/hooks/useProduct';
import {SearchProduct} from '@winelivery-org/wl-client-sdk-js/dist/models/search';
import {useFormatter} from 'next-intl';
import Image from 'next/image';
import {Link} from '../../../navigation';

interface Props {
  product: SearchProduct;
}

export default function CartItem(props: Props) {
  const format = useFormatter();
  const {quantityInCart, addToCart, removeFromCart} = useProduct(props.product);

  return (
    <li className="tw-flex tw-space-x-4">
      <div className="tw-relative tw-flex tw-items-center tw-bg-white tw-rounded tw-py-2 tw-overflow-hidden tw-w-28 tw-h-28 tw-shrink-0 tw-border tw-border-greyscale-separator">
        <Link
          href={{pathname: '/shop/[id]', params: {id: props.product.slug!}}}
          className="tw-no-underline"
        >
          <div className="tw-relative tw-h-28 tw-w-28">
            <Image
              fill
              className="tw-object-contain tw-transition tw-ease-in-out tw-duration-500 hover:tw-scale-125"
              src={props.product.imageUrl || ''}
              alt={props.product.name}
            />
          </div>
        </Link>
      </div>

      <div className="tw-flex tw-flex-col tw-justify-between tw-w-full">
        <div className="tw-group tw-cursor-pointer">
          <Link
            href={{pathname: '/shop/[id]', params: {id: props.product.slug!}}}
            className="tw-no-underline"
          >
            <h3 className="tw-text-base tw-font-bold tw-tracking-tight tw-text-greyscale-body tw-m-0 tw-leading-5 group-hover:tw-underline">
              {props.product.name}
            </h3>
            <p className="tw-text-sm tw-text-greyscale-label tw-font-medium tw-tracking-tight">
              {props.product.producer}
            </p>
          </Link>
        </div>

        <div className="tw-flex tw-justify-between tw-items-center">
          <div>
            {!!props.product.discountedPrice && (
              <div className="tw-text-sm tw-line-through tw-text-greyscale-label tw-font-medium -tw-mb-1">
                {format.number(props.product.price * quantityInCart, {
                  style: 'currency',
                  currency: 'EUR',
                })}
              </div>
            )}
            <div className="tw-text-greyscale-body tw-font-bold">
              {format.number(
                (props.product.discountedPrice || props.product.price) *
                  quantityInCart,
                {style: 'currency', currency: 'EUR'},
              )}
            </div>
          </div>

          <QuantityManager
            size="sm"
            quantity={quantityInCart}
            onAdd={addToCart}
            onSub={removeFromCart}
          />
        </div>
      </div>
    </li>
  );
}
