'use client';

import type { ReactElement } from 'react';
import RightButtons from 'src/components/header/RightButtons';
import Logo from './Logo';
import Nav from './Nav';

interface Props {
  className?: string;
  renderLeft?: ReactElement;
  renderCenter?: ReactElement;
  renderRight?: ReactElement;
  renderTop?: ReactElement;
  renderBottom?: ReactElement;
}

export default function Header(props: Props) {
  function renderLeft() {
    return props.renderLeft || <Logo />;
  }

  function renderCenter() {
    return props.renderCenter || <Nav />;
  }

  function renderRight() {
    return props.renderRight || <RightButtons />;
  }

  function renderTop() {
    return props.renderTop || null;
  }

  function renderBottom() {
    return props.renderBottom || null;
  }

  return (
    <header className="tw-sticky tw-top-0 tw-z-20">
      <div
        className={
          props.className ||
          'tw-border-b tw-border-greyscale-separator tw-bg-white'
        }
      >
        {renderTop()}
        <div className="tw-container tw-mx-auto tw-flex tw-items-center tw-justify-between tw-gap-6 tw-py-4 tw-px-4 lg:tw-px-6 xl:tw-px-8">
          <div className="md:tw-min-w-[10rem]">{renderLeft()}</div>
          <div className="tw-flex-auto">{renderCenter()}</div>
          <div className="md:tw-min-w-[10rem] tw-flex tw-justify-end">
            {renderRight()}
          </div>
        </div>
        {renderBottom()}
      </div>
    </header>
  );
}
