export default function useNavlinks() {
  return [
    {
      href: {
        pathname: '/shop',
      },
      label: 'headerDefaultNavlink.shop',
    },
    {
      href: {
        pathname: '/experience',
      },
      label: 'headerDefaultNavlink.experiences',
    },
    {
      href: {
        pathname: '/wallet',
      },
      label: 'headerDefaultNavlink.wallet',
    },
    {
      href: {
        pathname: '/giftcard',
      },
      label: 'headerDefaultNavlink.giftCard',
    }
  ];
}
