import { useTranslations } from 'next-intl';
import { Link } from '../../navigation';
import useNavlinks from './useNavLinks';

export default function Nav() {
  const t = useTranslations('common');
  const navLinks = useNavlinks();
  return (
    <nav className="tw-hidden md:tw-flex tw-justify-center md:tw-gap-6">
      {navLinks.map((item) => {
        return (
          <Link<any>
            key={item.label}
            href={item.href}
            className="tw-text-center tw-text-base tw-font-medium tw-tracking-tight tw-text-greyscale-label tw-py-3 tw-no-underline hover:tw-text-greyscale-title"
          >
            {t(item.label)}
          </Link>
        );
      })}
    </nav>
  );
}
