'use client';

import { useState } from 'react';
import useCart from '../../app/[locale]/shop/components/hooks/useCart';
import DrawerCart from '../drawers/DrawerCart';
import { Bag } from '../icons';

function ButtonCart() {
  const [isOpen, setIsOpen] = useState(false);
  const { isEmpty, items, totalQuantity, removeProduct } = useCart();

  return (
    <>
      <button
        className="tw-flex tw-items-center tw-justify-center tw-rounded-full tw-text-greyscale-title tw-h-10 tw-w-10 tw-relative"
        onClick={(e) => {
          e.stopPropagation();
          setIsOpen(true);
        }}
      >
        <Bag className="tw-h-6 tw-w-6 tw-shrink-0" />
        {!isEmpty && (
          <div className="tw-absolute tw-top-0 tw-right-0 tw-flex tw-items-center tw-justify-center tw-bg-primary tw-text-white tw-text-xs tw-font-medium tw-w-4 tw-h-4 tw-rounded-full">
            {totalQuantity}
          </div>
        )}
      </button>
      <DrawerCart
        isOpen={isOpen}
        toggle={() => setIsOpen((prev) => !prev)}
        items={items}
        deleteProduct={removeProduct}
      />
    </>
  );
}

export default ButtonCart;
