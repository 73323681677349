import { SearchProduct } from '@winelivery-org/wl-client-sdk-js/dist/models/search';

// import { useLocalization } from '@/hooks/useLocalization';
import useCartStore from '@/stores/cart/store';
import { useTranslations } from 'next-intl';
import { toast } from 'react-toastify';
import * as GTM from '../../../../../lib/GTM';
import * as service from '../../../../../services/user';
import useSession from '../../../../../stores/session/useSession';
// import NoAddressError from '../errors/NoAddressError';

export default function useProduct(product: SearchProduct) {
  const t = useTranslations('common');
  const { user, setUser } = useSession();

  const isInWishList = !user ? false : user.wishlist.includes(product.id);

  const quantityInCart = useCartStore((cart) => {
    const item = cart.items.find((p) => p.product.id === product.id);
    return item ? item.quantity : 0;
  });

  const { addItem, removeItem } = useCartStore((state) => state);

  // const { az } = useLocalization();

  function addToCart() {
    // if (!az) {
    //   throw new NoAddressError('no address specified');
    // }
    if (quantityInCart >= product.stock) {
      return toast.info(t('addToCart.maxQuantityReached'));
    }
    GTM.addToCart(product, quantityInCart + 1);
    addItem(product);
  }

  async function addToWishList() {
    if (!user || isInWishList) {
      return;
    }

    const { ok } = await service.addToWishList(product.id);
    if (!ok) {
      return;
    }

    setUser({ ...user, wishlist: [...user.wishlist, product.id] });
  }

  function removeFromCart() {
    removeItem(product, 1);
  }

  async function removeFromWishList() {
    if (!user || !isInWishList) {
      return;
    }

    const { ok } = await service.removeFromWishList(product.id);
    if (!ok) {
      return;
    }

    setUser({
      ...user,
      wishlist: user.wishlist.filter((id) => id !== product.id),
    });
  }

  return {
    isInCart: quantityInCart > 0,
    isInWishList,
    quantityInCart,
    addToCart,
    addToWishList,
    removeFromCart,
    removeFromWishList,
  };
}
