import useCartStore from '@/stores/cart/store';
import { SearchProduct } from '@winelivery-org/wl-client-sdk-js/dist/models/search';
import { useMemo } from 'react';
import useStore from '../../../../../stores/cart/useStore';

export default function useCart() {
  const cart = useStore(useCartStore, (state) => state);

  const totalQuantity = useMemo(() => {
    if (!cart) {
      return 0;
    }
    return cart.items.reduce((acc, curr) => acc + curr.quantity, 0);
  }, [cart]);

  const subtotal = useMemo(() => {
    if (!cart) {
      return 0;
    }

    return cart.items.reduce(
      (acc, curr) => acc + curr.quantity * curr.product.price,
      0,
    );
  }, [cart]);

  function removeProduct(product: SearchProduct) {
    cart && cart.removeItem(product, 1);
  }

  function reset() {
    cart && cart.emptyCart();
  }

  return {
    isEmpty: cart ? cart.items.length === 0 : true,
    items: cart ? cart.items : [],
    subtotal: subtotal || 0,
    totalQuantity: totalQuantity || 0,
    removeProduct,
    reset,
  };
}
