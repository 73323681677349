import { useTranslations } from 'next-intl';
import { Button } from 'src/components/design-system';
import { useRouter } from '../../../navigation';
import useSession from '../../../stores/session/useSession';

export interface Props {
  closeMenu: any;
  isCartEmpty: boolean;
}

export default function CheckoutButton(props: Props) {
  const { user } = useSession();
  const router = useRouter();
  const t = useTranslations('common');

  const onCheckoutClick = () => {
    props.closeMenu(false);

    if (!user) {
      return router.push({
        pathname: `/auth/signin`,
        query: { redirect: `/checkout` },
      });
    }

    router.push({ pathname: '/checkout' });
  };

  return (
    <Button
      disabled={props.isCartEmpty}
      onClick={onCheckoutClick}
      className="tw-w-full"
    >
      {t('CheckoutButton.checkout')}
    </Button>
  );
}
