import numeral from 'numeral';
import React from 'react';

interface IProps {
  price: number;
  discountedPrice?: number;
  quantity?: number;
}

export const Price: React.FC<IProps> = ({
  price,
  discountedPrice,
  quantity = 1,
}) => (
  <span>
    {discountedPrice !== undefined ? (
      <>
        <span style={{ color: '#bbb', whiteSpace: 'nowrap' }}>
          <span id="price-container">
            <span className="tw-font-normal tw-text-base">€</span>
            {numeral(price * quantity).format('0.00')}
          </span>
        </span>{' '}
        <span style={{ whiteSpace: 'nowrap' }} id="price-container">
          <span className="tw-font-normal tw-text-base">€</span>
          {numeral(discountedPrice * quantity).format('0.00')}
        </span>
      </>
    ) : (
      <span style={{ whiteSpace: 'nowrap' }} id="price-container">
        <span className="tw-font-normal tw-text-base">€</span>
        {numeral(price * quantity).format('0.00')}
      </span>
    )}
  </span>
);
