'use client';

import ButtonAccount from '@/components/header/ButtonAccount';
import { useTranslations } from 'next-intl';
import dynamic from 'next/dynamic';
import useSession from '../../stores/session/useSession';
import { Button } from '../design-system';
import ButtonCart from './ButtonCart';

const LinkToLogin = dynamic(() => import('../commons/LinkToLogin'), {
  ssr: false,
});

export default function RightButtons() {
  const t = useTranslations('common');
  const { user } = useSession();

  return (
    <div className="tw-flex tw-items-center tw-gap-1">
      {!user && (
        <div className="tw-mr-2">
          <LinkToLogin>
            <Button palette="gray" type="link">
              {t('action.login')}
            </Button>
          </LinkToLogin>
        </div>
      )}
      <ButtonCart />
      <ButtonAccount />
    </div>
  );
}
