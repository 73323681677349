import { Price } from 'src/components/commons/Price';
import { useTranslations } from 'next-intl';
import CheckoutButton from './CheckoutButton';

interface Props {
  totalPrice: number;
  closeMenu: () => void;
  isCartEmpty: boolean;
}

export default function Footer(props: Props) {
  const t = useTranslations('common');
  return (
    <div className="tw-space-y-4 tw-p-6">
      <div className="tw-flex tw-justify-between tw-font-bold tw-text-greyscale-title tw-text-xl">
        <div className="text-lg">{t('DrawerCart.total')}</div>
        <Price price={props.totalPrice} />
      </div>
      <CheckoutButton
        closeMenu={props.closeMenu}
        isCartEmpty={props.isCartEmpty}
      />
    </div>
  );
}
