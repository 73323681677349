import { SearchProduct } from '@winelivery-org/wl-client-sdk-js/dist/models/search';
import _ from 'lodash';
import { CartItem } from '../../../stores/cart/types';
import FlyoutMenu from '../FlyoutMenu';
import Header from '../Header';
import CartList from './CartList';
import Footer from './Footer';

interface Props {
  items: CartItem[];
  deleteProduct: (product: SearchProduct) => void;
  isOpen: boolean;
  toggle: () => void;
}

export default function DrawerCart(props: Props) {
  const totalPrice = props.items.reduce(
    (a, b) =>
      a + ((b.product.discountedPrice || b.product.price) * b.quantity || 0),
    0,
  );

  if (!props.isOpen) {
    return null;
  }

  return (
    <div className="tw-relative sm:tw-block tw-contents">
      <FlyoutMenu
        isOpen={props.isOpen}
        onCloseMenu={props.toggle}
        header={<Header onCloseMenu={props.toggle} />}
        footer={
          <Footer
            closeMenu={props.toggle}
            totalPrice={totalPrice}
            isCartEmpty={_.isEmpty(props.items)}
          />
        }
      >
        <div className="sm:tw-max-h-[65vh] sm:tw-w-96 tw-p-6 tw-flex-1 tw-overflow-auto sm:tw-rounded-b-xl tw-bg-white">
          <CartList items={props.items} />
        </div>
      </FlyoutMenu>
    </div>
  );
}
